// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';

// import favicon here.
import './resources/images/favicon.png';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; // carousel instances
    _tabbyInstances: any; // tabset instances
  }
}

// handles navigation related click events emitted by the bus
const handleNavigationClick = function (data) {
  if (data?.url) {
    const hash = data.url.split('#')[1];

    if (hash) {
      if (hash === 'isi') {
        const isi = document.querySelector(
          '[data-component="container-isi-v2"]'
        );
        if (isi) {
          const isiPos = (isi.getBoundingClientRect()?.y || 0) - 100; // reducing so as to not overlap heading of the section and the fixed header
          window.scrollTo({
            top: window.scrollY + isiPos,
            left: 0,
            behavior: 'smooth',
          });
        }

        // closing the menu if it is open
        const closeTrigger = document.querySelector(
          '.header__content button.js-toggle-on'
        ) as HTMLButtonElement;
        closeTrigger?.click();
      } else {
        const header = document.querySelector('.cmp-container-header');
        const headerHeight = header?.clientHeight || 0;
        const pageSection = document.getElementById(hash);
        let additionalSpace = headerHeight;
        const sectionTop =
          (pageSection?.getBoundingClientRect().y || 0) - additionalSpace; // reducing so as to not overlap heading of the section and the fixed header

        window.scrollTo({
          top: window.scrollY + sectionTop,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }
};

const init = function () {
  if (window.Bus) {
    // listen to events here
    window.Bus.on('emu-navigation:clickItem', data => {
      handleNavigationClick(data);
    });

    const gallery = document.querySelector('#gallery');
    if (gallery) {
      const galleryStageIframes = gallery.querySelectorAll('iframe');
      window.Bus.on('emu-gallery:itemClick', data => {
        if (data?.id?.indexOf('gallery-preview') >= 0) {
          galleryStageIframes.forEach(iframe => {
            const closestStageItem = iframe.closest('.emu-gallery__stage-item');
            const isClosestStageItemActive =
              closestStageItem?.classList.contains('.js-active');
            if (!isClosestStageItemActive) {
              const src = iframe.getAttribute('src')!;
              iframe.setAttribute('src', src);
            }
          });
        }
      });
    }
  }

  // changing header layout when page is scrolled
  const eyebrowEl = document.querySelector('.header__eyebrow-main');
  if (eyebrowEl) {
    const manageHeaderFixedClass = function () {
      if (
        window.scrollY > eyebrowEl.clientHeight &&
        !document.body.classList.contains('r-banner-open')
      ) {
        document.body.classList.add('has-header-fixed');
      } else {
        document.body.classList.remove('has-header-fixed');
      }
    };

    manageHeaderFixedClass();
    window.addEventListener('scroll', manageHeaderFixedClass);
  }

  // when URL has hash, scrolling the page to the corresponding section
  const hash = window.location.hash;
  if (hash) {
    window.addEventListener('load', () => {
      // timer to make sure that the initial scroll position is calculated
      const timer = setTimeout(() => {
        handleNavigationClick({
          url: window.location.href,
        });
        clearTimeout(timer);
      }, 400);
    });
  }

  // on page load, checking the anchor urls in menu, whether an element with the hash id is present on the page
  // if an element is present adding an event to scroll the page to that element when clicked on that anchor
  document
    .querySelectorAll('nav .emu-navigation__item.emu-navigation__item-parent a')
    .forEach(el => {
      const href = el?.getAttribute('href') || '';
      const hash = href.split('#')[1];
      if (hash && document.querySelector(`#${hash}`)) {
        el.addEventListener('click', e => {
          e.preventDefault();
          handleNavigationClick({
            url: `#${hash}`,
          });
        });
      }
    });

  // FAP related code
  const fapContainer = document.querySelector('.kyb-fap');
  if (fapContainer) {
    const form = fapContainer.querySelector('form');
    const zipInp = form?.querySelector(
      '.cmp-form-text__text'
    ) as HTMLInputElement;
    const fapDeskTrigger = fapContainer.querySelector('.image');
    const fapDeskClose = fapContainer.querySelector('.kyb-fap__close');

    // adding placeholder as there is no feasibility to add one in author
    zipInp?.setAttribute('placeholder', 'ZIP CODE*');

    const ghostLink = document.createElement('a');
    ghostLink.target = '_blank';

    // submit event of form to redirect to fas page
    form?.addEventListener('submit', e => {
      e.preventDefault();
      const zip = zipInp.value?.trim();

      // validation picked from FAS.js - https://www.mykybella.com/Components/MyKybella/Content/js/FAS.js?1
      if (/^\d{5}$/.test(zip)) {
        ghostLink.href = `https://alle.com/search?brand=kybella&location=${zipInp.value}&${localStorage.getItem('brand_ast_marketing_params')}`;
        ghostLink.click();
      } else {
        zipInp.classList.add('error');
      }
    });

    // logic to show/hide fap widget in tablet and desktop
    fapDeskTrigger?.addEventListener('click', () => {
      fapContainer.classList.add('kyb-fap--shown');
    });

    // logic to hide fap widget in tablet and desktop
    fapDeskClose?.addEventListener('click', () => {
      fapContainer.classList.remove('kyb-fap--shown');
    });
  }

  // isi link click event in header
  const isiLinks = document.querySelectorAll('[href="#isi"]');
  if (isiLinks?.length) {
    isiLinks.forEach(link => {
      link.addEventListener('click', e => {
        e?.preventDefault();
        handleNavigationClick({
          url: '#isi',
        });
      });
    });
  }
};

if (
  document.readyState === 'complete' ||
  document.readyState === 'interactive'
) {
  init();
} else {
  document.addEventListener('DOMContentLoaded', init);
}
